import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Grid, InputAdornment, TextField } from '@mui/material';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';

import { useCases } from '../../../core/planning';
import PageHeaderBar from '../../common/layout/components/PageHeaderBar';
import { FormGridItem } from '../../common/form-field';
import { ViewCard } from '../../common/layout/components';
import ValidateBeforeSigningForm from './ValidateBeforeSigningForm';
import BonSignatureAlertStatus from './BonSignatureAlertStatus';

interface Props {
  planningId?: string;
}



export function PlanningValidationView(props: Props) {
  const { id: planningId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const [frame, setFrame] = useState<string>();
  const [currentBon, setCurrentBon] = useState<any>(null);
  const [signed, setSigned] = useState(false);
  const [needToBeSign, setNeedToBeSign] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await dispatch(useCases.retreiveBon(planningId as string));
      setCurrentBon(res.payload);
      setSigned(res.payload?.procedure?.fournisseurConfirmation);
      needToBeSign && setFrame(res.payload.procedure.fournisseur);
    })();
  }, []);

  const onClickConfirm = async (immatriculation: string, fraisAutoroute?: number, observation?: string) => {
    let signUrl: string;
    if (currentBon && currentBon?.procedure?.fournisseur) {
      signUrl = currentBon.procedure.fournisseur;
    } else {
      const unwrapProcedure = await dispatch(
        useCases.startProcedure({ planningId, fraisAutoroute, observation, immatriculation })
      ).unwrap();
      signUrl = unwrapProcedure.chauffeurLink;
    }
    //console.log("signUrl"); //console.log(signUrl);
    setFrame(signUrl);
  };

  useEffect(() => {
    if (currentBon) {
      setNeedToBeSign(
        currentBon.status === 'DRAFT' ||
        (currentBon.status === 'CREATED' && !currentBon.procedure.fournisseurConfirmation)
      );
    }
  }, [currentBon]);


  const reload = () => {
    setFrame('');
    navigate(-1);
  };

  const isProcedurePending = !!currentBon?.procedure?.client && !!currentBon?.procedure?.fournisseur;


  if (frame && needToBeSign) {
    return (
      <>
        <iframe title="Signature bon" style={{ width: '100%', height: '90vh' }} src={`${frame}`}></iframe>
        <Button variant="contained" color="primary" onClick={reload}>
          REVENIR
        </Button>
      </>
    );
  }

  return (
    <>
      <PageHeaderBar title="Confirmer les informations" />
      <ViewCard>
        <BonSignatureAlertStatus
          fournisseurConfirmed={signed}
          status={currentBon?.status}
          isProcedureStarted={isProcedurePending}
        />
        <Grid container direction="row" spacing={4} p={4} width={{ xs: '100%', md: '75%', xl: '60%' }}>
          <ValidateBeforeSigningForm
            bon={currentBon}
            onSubmit={onClickConfirm}
            needToBeSign={needToBeSign}
            isProcedurePending={isProcedurePending}

          />
        </Grid>
      </ViewCard >
    </>
  );
}

